@import "../../styles/Variables.module.scss";
@import "../../styles/Palette.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Mixin.module.scss";
@import "../../styles/Font.module.scss";

.price-label {
  color: $pqTextColor;
  position: relative;

  display: flex;
  color: $pqTextColor;
  font-weight: bold;
  font-size: $largefontSize;
  @include mediumDown {
    font-size: $basefontSize;
  }
  &__currency {
    top: 1.3px;
    font-size: $smallfontSize;
  }
}
