@import "../../styles/Palette.module.scss";
@import "../../styles/Font.module.scss";

.sticker {
  transform: rotate(20deg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 55px;

  border: 2px solid white;
  border-radius: 50%;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: $smallestfontSize;
  line-height: 15px;

  color: white;

  &--blue {
    background-color: $pqBlue;
  }

  &--green {
    background-color: $pqGreen;
    font-size: $basefontSize;
  }

  &--orange {
    background-color: $pqOrange;
  }

  &--success {
    background-color: $pqGreen;
  }

  &--warning {
    background-color: darken($pqYellow, 10%);
  }

  &--info {
    background-color: $pqBlue;
  }

  &--primary {
    background-color: $pqPrimary;
  }

  &--danger {
    background-color: $pqRed;
  }

  &.oppositeRotate {
    transform: rotate(-20deg);
  }
}
