@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";

.twoColumnsSide {
  @include largeUp() {
    width: 30%;
    padding-left: $mediumSpacing;
    &_left {
      padding: 0 $mediumSpacing 0 0;
    }
  }
}
