@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Mixin.module.scss";

.leaflet-cover {
  height: 215px;
  // width: 171.5px;
  width: 162.5px;
  position: relative;
  cursor: pointer;
  @include defaultBoxShadow(2);

  @include largeUp {
    width: 100%;
    height: 100%;
  }

  &_content {
    display: block;
    height: inherit;
    background-color: #efefef;
    text-align: center;
  }

  &_image {
    height: 100%;
  }

  &_sticker {
    position: absolute;
    top: $smallSpacing;
    right: $smallSpacing;
  }

  &_interactiveLabel {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &_open {
    & > [class*="overlay"] {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_label {
      position: relative;
      border: 1px solid #fff;
      border-radius: 4px;
      padding: 10px 20px;
      text-transform: uppercase;
    }
  }

  &_visited {
    color: #fff;

    & > [class*="overlay"] {
      top: unset;
      bottom: 0;
      height: auto;
      font-weight: bold;
      padding: $smallSpacing 0;
      text-align: center;
      text-transform: uppercase;

      & > [class*="pqIcon"] {
        margin-right: $smallSpacing;
      }
    }
  }

  &--is-expiring {
    .leaflet-cover {
      &_sticker {
        font-size: 0.7rem;
      }
    }
  }

  &--is-coupon {
    .leaflet-cover {
      &_sticker {
        font-size: 0.7rem;
      }
    }
  }

  &--is-preview {
    .leaflet-cover {
      &_sticker {
        font-size: 0.7rem;
      }
    }
  }
}
