@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";

.two-columns {
  display: flex;
  flex-direction: column;
  @include largeUp() {
    flex-direction: row;
    justify-content: space-between;
  }
}
