@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";

.twoColumnsMain {
  @include largeUp() {
    width: 70%;
    margin-bottom: 0;
  }

  margin-bottom: $normalSpacing;
}
