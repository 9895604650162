@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";

.inline-list {
  &__item {
    display: inline-block;
  }
}

.v-list {
  position: relative;

  &__item {
    &:not(:last-child):not(:empty) {
      margin-bottom: $baseSpacing;
    }
  }

  &--bordered {
    .v-list {
      &__item {
        &:not(:last-child):not(:empty) {
          border-bottom: $pqLightGray;
        }
      }
    }
  }
}

.h-list {
  display: flex;
  align-items: center;
  overflow: auto;

  &__item {
    &:not(:last-child):not(:empty) {
      margin-right: $baseSpacing * 3;
    }

    &:last-child:not(:empty) {
      padding-right: $baseSpacing * 3;
    }
  }
}
