@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/Mixin.module.scss";
@import "../../styles/MediaQueries.module.scss";

.gridContainer {
  padding-left: 32px;
  @include small() {
    padding-left: 16px;
  }
}

.mainInfosOnlyWeb {
  @include mediumDown() {
    display: none;
  }
}

.mainInfosOnlyMobile {
  .title {
    font-size: 14px !important;
  }

  @include largeUp() {
    display: none !important;
  }
}
.leafletsListItemCover {
  padding: 32px 0;
  @include mediumDown() {
    padding: 0;
  }
}
.leafletsListItemForRetailer {
  &__imageContainer {
    @include small() {
      display: none;
    }
  }

  &__details {
    .title {
      font-size: large;
    }

    .details {
      &__logo {
        line-height: 0;
        width: 24%;
        display: none;
      }

      &__infos {
        @include small() {
          flex-direction: row;
          margin-bottom: 10px;
          align-items: center;
          flex-wrap: wrap;
        }
        .infos {
          &__distance {
            @include mediumUp() {
              margin-right: 16px;
            }

            @include small() {
              margin-right: 10px;
            }
            // color: $pqMediumDarkGray;
          }

          &__distance,
          &__expiration {
            @include medium() {
              font-size: $smallfontSize;
            }
            //  color: $pqMediumDarkGray;
            font-weight: bold;
            @include small() {
              margin-right: 10px;
              font-size: 14px !important;
              white-space: nowrap;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
