@import "../../styles/Variables.module.scss";
@import "../../styles/Palette.module.scss";

.interactive-label {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 700;
  color: #e7e9ee;
  background-color: rgba($pqBlue, 0.8);
  padding: $smallSpacing;
  text-transform: uppercase;

  &__icon {
    margin-right: 4px;
    line-height: 0;
  }

  &__text {
    color: #e7e9ee;
  }
}
