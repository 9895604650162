@import "../../styles/Palette.module.scss";
@import "../../styles/Variables.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Mixin.module.scss";
@import "../../styles/Font.module.scss";

.leaflets-list-item-offers {
  .offer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &__image {
      line-height: 0;
      margin-right: $baseSpacing;
      text-align: right;
      align-self: center;

      &__img {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: $smallSpacing 0;
      }

      @include largeUp() {
        flex: 0 0 120px;
        margin-right: $mediumSpacing;
      }

      img {
        max-width: 30px;

        @include small() {
          max-width: 40px;
          width: 100%;
          height: 100%;
          min-width: 30px;
        }

        @include medium() {
          max-width: 35px;
        }

        @include largeUp() {
          max-width: 50px;
        }
      }
    }

    &__infos {
      flex: 1;

      .infos {
        color: $pqTextColor;
        line-height: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: $smallfontSize;
        &__priceAndDiscount {
          display: inline-flex;
          @include small() {
            display: flex;
/*             flex-wrap: wrap;
 */            width: 100%;
/*             height: 50px;
 */          }
        }
        &__price {
          font-weight: bold;
          margin-right: $smallSpacing;

          & ~ .infos__title {
            margin-top: $smallSpacing;
            &:first-child {
              @include mediumDown() {
                margin-top: 0;
              }
            }
          }
          @include largeUp() {
            margin-right: calc($baseSpacing * 2);
            font-size: $mediumfontSize;
          }
        }

        &__discount {
          line-height: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          color: white;
          background-color: $pqOrange;
          padding: calc($smallSpacing / 2);
          margin: 2px 0;
          font-size: $smallfontSize;

          @include largeUp() {
            font-size: 1rem;
          }
          @include small() {
            height: 18px;
/*             margin-top: -10px;
 */          }
        }

        &__title {
          display: inline-block;
          color: $pqTextMuted;
          flex: 0 0 100%;
          line-height: 1.2;

          font-size: $smallfontSize;
          //max-height: calc($smallfontSize * 4 * $normalLineHeight);

          @include lineClamp(4);

          @include largeUp() {
            font-size: $basefontSize;
            // max-height: calc(#{$basefontSize}* 4 *#{$normalLineHeight});
          }
          @include small() {
            line-break: anywhere;
          }
        }
      }
    }
  }
}
