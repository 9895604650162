@import "../../styles/Variables.module.scss";
@import "../../styles/Palette.module.scss";
@import "../../styles/MediaQueries.module.scss";
@import "../../styles/Mixin.module.scss";
@import "../../styles/Font.module.scss";

$lineClampVal: 2;

.leafletFooter {

  &_title {
    font-size: $basefontSize;
    font-weight: bold;
    overflow: hidden;
    color: $pqTextColor;
    text-transform: uppercase;
    @include lineClamp($lineClampVal);
  }

  &_subtitle {
    color: $pqMediumDarkGray;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_dateanddistance {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: $pqTextMuted;
    &_expiring {
      color: $pqOrange;
    }
  }
  &_location {
    font-size: 12px;
    font-weight: 600;
  }
  i[class*="pqIcon"] {
    margin-right: calc($baseSpacing / 2);
  }
}
