@import "../../styles/Variables.module.scss";

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity $baseAnimationDuration $baseAnimationTimingFunction;
  background-color: rgba(black, 0.7);
  color: white;

  &--show {
    opacity: 1;
  }
}
